// import { useCallback, useState } from "react";

// export const useVideoCapture = () => {
//   const [video, setVideo] = useState<string | null>(null);
//   const [isVideoReady, setIsVideoReady] = useState(false);

//   const startVideoCapture = useCallback(() => {
//     const captureVideo = async () => {
//       if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
//         const stream = await navigator.mediaDevices.getUserMedia({
//           video: {
//             width: { ideal: 640 }, // Lower resolution to reduce size
//             height: { ideal: 360 },
//           },
//         });
//         const mediaRecorder = new MediaRecorder(stream, {
//           videoBitsPerSecond: 1000000,
//           mimeType: "video/webm",
//         });

//         const recordedChunks: BlobPart[] = [];

//         mediaRecorder.addEventListener("dataavailable", (event) => {
//           if (event.data.size > 0) {
//             recordedChunks.push(event.data);
//           }
//         });

//         mediaRecorder.addEventListener("stop", async () => {
//           const blob = new Blob(recordedChunks, { type: "video/webm" });
//           const reader = new FileReader();
//           reader.onloadend = () => {
//             setVideo(reader.result as string);
//             setIsVideoReady(true);
//           };
//           reader.readAsDataURL(blob);
//         });

//         mediaRecorder.start();
//         setTimeout(() => mediaRecorder.stop(), 10000);
//       }
//     };

//     captureVideo();
//   }, []);
//   return { video, isVideoReady, startVideoCapture };
// };

import { useCallback, useState } from "react";

export const useVideoCapture = () => {
  const [video, setVideo] = useState<string | null>(null);
  const [isVideoReady, setIsVideoReady] = useState(false);

  const startVideoCapture = useCallback(() => {
    const captureVideo = async () => {
      try {
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
          const stream = await navigator.mediaDevices.getUserMedia({
            video: {
              width: { ideal: 640 },
              height: { ideal: 360 },
              frameRate: { ideal: 15 }, // Reduce frame rate for faster processing
            },
          });

          const mediaRecorder = new MediaRecorder(stream, {
            videoBitsPerSecond: 500000, // Reduce bitrate
            mimeType: "video/webm",
          });

          const recordedChunks: BlobPart[] = [];

          mediaRecorder.ondataavailable = (event) => {
            if (event.data.size > 0) {
              recordedChunks.push(event.data);
            }
          };

          mediaRecorder.onstop = () => {
            const blob = new Blob(recordedChunks, { type: "video/webm" });
            setVideo(URL.createObjectURL(blob)); // Directly use blob URL for faster access
            setIsVideoReady(true);
          };

          mediaRecorder.start();
          setTimeout(() => mediaRecorder.stop(), 8000); // Shorten capture time
        }
      } catch (error) {
        console.error("Video capture failed:", error);
      }
    };

    captureVideo();
  }, []);

  return { video, isVideoReady, startVideoCapture };
};
