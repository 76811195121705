import { Button } from "@/components";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import completeIcon from "@/assets/completeIcon.svg";
import Webcam from "react-webcam";
import { State } from "@/states/livenessCheckState/actionCreators";

type VerificationProps = {
	state: State;
	hanleSwitchCamera: () => void;
	handleCaptureAndSubmit: () => void;
	isVideoReady: boolean;
	WebcamWithFaceDetectionMemo: React.ReactNode;
	webcamRef: React.RefObject<Webcam>;
	videoConstraints: MediaTrackConstraints;
};

export default function Verification({
	state,
	hanleSwitchCamera,
	handleCaptureAndSubmit,
	isVideoReady,
	WebcamWithFaceDetectionMemo,
	webcamRef,
	videoConstraints,
}: VerificationProps) {
	return (
		<div className="flex justify-evenly items-center flex-col h-screen">
			{/*<img src="/logo.svg" alt="logo" />*/}
			<div className="md:flex md:flex-col md:items-center md:justify-center">
				<div className="flex flex-col items-center justify-center md:mt-3">
					<h1 className="font-medium text-sm text-slate-950">Liveness Verification</h1>
					<div className="relative flex items-center justify-center p-8">
						<CircularProgressbar
							strokeWidth={5}
							maxValue={100}
							value={state.percentage}
							className="absolute "
							styles={buildStyles({
								pathColor: "#36CC4F",
							})}
						/>
						<div className="size-[200px] overflow-hidden rounded-full md:size-[270px]">
							{!state.done && WebcamWithFaceDetectionMemo}
							{state.done && !state.capturedImage && (
								<Webcam
									audio={false}
									ref={webcamRef}
									className="size-full object-cover"
									screenshotFormat="image/jpeg"
									imageSmoothing={true}
									videoConstraints={videoConstraints}
								/>
							)}
							{state.done && state.capturedImage && (
								<img
									src={state.capturedImage}
									className="size-full object-cover"
									alt="Captured"
									loading="lazy"
								/>
							)}
						</div>
					</div>
				</div>
			</div>
			<div className="mt-10 flex flex-col items-center justify-center">
				<h3 className="mb-5 text-sm font-medium text-lightDark">{state.message}</h3>
				{state.done && (
					<img src={completeIcon} width={20} height={20} alt="complete" loading="lazy" />
				)}
			</div>
			{state.done && (
				<>
					<p className="text-sm text-center">
						Please note that the video captured may be saved. By tapping 'Capture', you consent to
						saving it.
					</p>
					<Button
						className="w-[200px]"
						onClick={handleCaptureAndSubmit}
						isLoading={state.isSubmitting}
						disabled={!isVideoReady}
					>
						{isVideoReady ? "Capture" : "Preparing..."}
					</Button>
				</>
			)}
			<Button variant="ghost" onClick={hanleSwitchCamera}>
				Switch camera
			</Button>
		</div>
	);
}
